<template>
  <v-card color="teal lighten-5">
    <v-card-text>
      <h2>{{ title }}</h2>
      <v-form ref="answerForm">
        <ol>
          <li class="mt-4" v-for="(obj, oI) in defaultQuestions" :key="oI">
            <div v-if="obj.value == 'birthdate'">
              {{ obj.text }}
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="answerData[obj.value]"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[(v) => !!v || 'この項目は必須です']"
                    :disabled="!answer"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="answerData[obj.value]"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="save"
                  locale="ja-jp"
                  :disabled="!answer"
                ></v-date-picker>
              </v-menu>
            </div>
            <div v-else-if="obj.value == 'gender'">
              {{ obj.text }}
              <v-radio-group
                row
                v-model="answerData[obj.value]"
                :rules="[(v) => !!v || 'この項目は必須です']"
                :disabled="!answer"
              >
                <v-radio label="男性" value="男性"></v-radio>
                <v-radio label="女性" value="女性"></v-radio>
                <v-radio label="その他" value="その他"></v-radio>
              </v-radio-group>
            </div>
            <div v-else>
              {{ obj.text }}
              <v-text-field
                :label="obj.label"
                single-line
                outlined
                class="mt-2"
                v-model="answerData[obj.value]"
                :rules="[(v) => !!v || 'この項目は必須です']"
                :disabled="!answer"
              ></v-text-field>
            </div>
          </li>
          <li>
            説明<br />
            <div class="mt-4 mb-8">
              <a href="/fluExplanation.pdf" target="_blank"
                >インフルエンザ予防接種についての説明</a
              >
            </div>
          </li>
          <li
            v-for="(question, i) in answerData.questions"
            :key="i + 100"
            class="mt-4"
          >
            {{ question.text }}
            <v-radio-group
              row
              v-model="question.radioAnswer"
              :rules="[(v) => !!v || 'この項目は必須です']"
              :disabled="!answer"
            >
              <v-radio label="はい" value="y"></v-radio>
              <v-radio label="いいえ" value="n"></v-radio>
            </v-radio-group>
            <v-textarea
              outlined
              :label="question.memo"
              v-if="question.memo"
              v-model="question.memoAnswer"
              :counter="20"
              :rules="[(v) => v.length <= 20 || '20文字以内で記入してください']"
              :disabled="!answer"
            ></v-textarea>
          </li>
        </ol>
      </v-form>
    </v-card-text>
    <v-row justify="end" class="mr-4 pb-4" v-if="answer">
      <v-btn color="primary" class="float-right" @click="completeAnswer"
        >QRコードを生成する</v-btn
      >
    </v-row>
  </v-card>
</template>

<script>
import FrontCommon from "@/assets/js/frontCommon";

export default {
  props: ["data", "answer", "edit"],
  components: {},
  data: () => ({
    title: "",
    answerData: {
      questions: [],
    },
    defaultQuestions: [],
    menu: false,
  }),
  created() {
    this.defaultQuestions = FrontCommon.defaultQuestions();
    this.defaultQuestions.forEach((obj) => {
      this.answerData[obj.value] = "";
    });
    if (this.data) {
      this.title = this.data.title;
      if (!this.edit) {
        const questions = [];
        this.data.questions.forEach((element) => {
          const elementNew = { ...element };
          elementNew.memoAnswer = "";
          elementNew.radioAnswer = "";
          questions.push(elementNew);
        });

        this.answerData.questions = questions;
      }
    }
  },
  methods: {
    completeAnswer() {
      if (this.$refs.answerForm.validate()) {
        this.$emit("make-qr-code", this.answerData);
      }
    },
    save(date) {
      console.log(this.$refs.menu);
      this.$refs.menu[0].save(date);
    },
  },
  watch: {
    menu(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.picker[0].activePicker = "YEAR";
        });
      }
    },
  },
  filters: {},
};
</script>
